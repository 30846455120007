<template>
  <v-app>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">User</h3>
            </div>
            <div class="card-toolbar">
              <button @click="formModal(null)" class="btn btn-primary">
                Add
              </button>
            </div>
          </div>
          <div class="card-body">
            <b-table hover :items="listUsers" :fields="users.fields">
              <template #cell(last_exp)="data">
                {{ data.value == "" ? "" : dateMoment(data.value).format("DD/MM/YYYY HH:mm:ss") }}
              </template>
              <template #cell(level_akses)="data">
                <b-badge v-if="data.value == 1" variant="info">Admin</b-badge>
                <b-badge v-else variant="primary">User</b-badge>
              </template>
              <template #cell(action)="data">
                <div class="text-right">
                  <b-button-group>
                    <b-button size="sm" variant="outline-primary" @click="formModal(data.item.username)"> <b-icon-pencil /> Edit </b-button>
                    <b-button size="sm" variant="outline-primary" @click="modalToken(data.item.username)"> <b-icon-dice6 /> Login Token </b-button>
                    <b-button size="sm" variant="outline-primary" @click="hapusUser(data.item.username)"> <b-icon-trash /> Hapus </b-button>
                  </b-button-group>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="modal-add-user" v-bind:title="form.edit == false ? 'Edit User' : 'Add User'" @ok.prevent="simpanUser()">
      <b-form>
        <b-form-group id="ig_token_berjangka" label="Register Data:" label-for="input_register_data">
          <b-input-group>
            <b-form-textarea id="input_register_data" ref="input_register_data" type="textarea" rows="4" placeholder="Register Data" v-model="input_register_data" required></b-form-textarea>
          </b-input-group>
        </b-form-group>
        <b-row>
          <b-col sm="3">Nama Lengkap</b-col>
          <b-col sm="9">: {{ form.nama_lengkap }}</b-col>
        </b-row>
        <b-row>
          <b-col sm="3">Email</b-col>
          <b-col sm="9">: {{ form.username }}</b-col>
        </b-row>
        <b-row>
          <b-col sm="3">Password Hash</b-col>
          <b-col sm="9">: {{ form.password_hash }}</b-col>
        </b-row>
        <b-row>
          <b-col sm="3">UUID</b-col>
          <b-col sm="9">: {{ form.uuid }}</b-col>
        </b-row>
        <b-form-group id="ig_level_akses" label="Level Akses:" label-for="input_level_akses">
          <b-form-select id="input_level_akses" v-model="form.level_akses" :options="level_akses_options" required></b-form-select>
        </b-form-group>
        <b-form-group label="Fitur">
          <b-form-checkbox-group id="checkbox-group-1" v-model="form.fitur" :options="options_fitur" name="flavour-1"></b-form-checkbox-group>
        </b-form-group>
      </b-form>
      <b-form-group id="ig_token_register" label="Token Aktivasi:" label-for="input_token_register">
        <b-input-group>
          <b-form-textarea id="input_token_register" ref="input_token_register" type="textarea" rows="4" placeholder="Token Register" readonly v-bind:value="token_register" required></b-form-textarea>
          <b-input-group-append>
            <b-button type="button" @click="copyTokenRegister()" size="sm" text="Button" variant="primary"><b-icon-clipboard /></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-modal>
    <b-modal ref="modal-token" v-bind:title="'Token Login'" ok-only>
      <h4>Token Berjangka</h4>
      <b-form-group id="ig_tanggal_expired_token" label="Berlaku Hingga:" label-for="input_tanggal_expired_token">
        <b-form-input id="input_tanggal_expired_token" type="datetime-local" placeholder="Tanggal" v-model="token.tanggal_expired_token" required></b-form-input>
      </b-form-group>
      <b-form-group id="ig_token_berjangka" label="Login Token Berjangka:" label-for="input_token_berjangka">
        <b-input-group>
          <b-form-textarea
            id="input_token_berjangka"
            ref="input_token_berjangka"
            type="textarea"
            rows="4"
            placeholder="Login Token Berjangka"
            readonly
            v-bind:value="token_berjangka"
            required
          ></b-form-textarea>
          <b-input-group-append>
            <b-button type="button" @click="copyTokenBerjangka()" size="sm" text="Button" variant="primary"><b-icon-clipboard /></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-modal>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import CryptoService from "@/core/services/crypto.service";
import moment from "moment";

export default {
  data() {
    return {
      token: {
        username: "",
        tanggal_token: new Date().toISOString().substring(0, 10),
        tanggal_expired_token: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7).toISOString().substring(0, 16),
      },
      input_register_data: "",
      form: {
        edit: false,
        register_data: "",
        password_hash: "",
        username: "",
        uuid: "",
        nama_lengkap: "",
        level_akses: 0,
        fitur: [],
      },
      level_akses_options: [
        { text: "Administrator", value: 1 },
        { text: "Pengguna", value: 0 },
      ],
      options_fitur: [
        { text: "Sinyal 1Inch BSC", value: "sinyal" },
        { text: "Sinyal 1Inch ERC20", value: "sinyal_erc" },
        { text: "Sinyal 1Inch ETH ERC20", value: "sinyal_1inch_eth_erc" },
        { text: "Sinyal 1Inch BNB BSC", value: "sinyal_1inch_bnb_bsc" },
        { text: "Sinyal 1Inch BTC BSC", value: "sinyal_1inch_btc_bsc" },
        { text: "Sinyal Matcha BNB BSC", value: "sinyal_matcha_bnb_bsc" },
        { text: "Sinyal Matcha BSC", value: "sinyal_matcha_bsc" },
        { text: "Sinyal Matcha ERC", value: "sinyal_matcha_erc" },
        { text: "Sinyal Paraswap BSC", value: "sinyal_para_bsc" },
        { text: "Sinyal Paraswap BNB BSC", value: "sinyal_paraswap_bnb_bsc" },
        { text: "Sinyal Dodo BSC", value: "sinyal_dodo_bsc" },
        { text: "Sinyal Huobi 1Inch ERC", value: "sinyal_huobi1inch_erc" },
        { text: "Sinyal Hotbit 1Inch BSC", value: "sinyal_hotbit_1inch_bsc" },
        { text: "Sinyal Hotbit Matcha BSC", value: "sinyal_hotbit_matcha_bsc" },
      ],
      users: {
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: "username",
            label: "Username",
            sortable: true,
          },
          {
            key: "nama_lengkap",
            label: "Nama lengkap",
            sortable: true,
          },
          {
            key: "level_akses",
            label: "Level akses",
            sortable: true,
          },
          {
            key: "last_exp",
            label: "Last Exp",
            sortable: false,
          },
          {
            key: "action",
            label: "",
            sortable: false,
          },
        ],
        items: [
          {
            username: "test",
            nama_lengkap: "Dickerson",
            level_akses: 1,
          },
        ],
      },
    };
  },
  watch: {
    input_register_data: function(nil) {
      try {
        let data = CryptoService.decrypt(nil);
        data = JSON.parse(data);
        this.form.register_data = nil;
        this.form.username = data.email;
        this.form.nama_lengkap = data.nama_lengkap;
        this.form.uuid = data.uuid;
        this.form.password_hash = data.password;
      } catch (e) {
        this.form.register_data = "";
        this.form.password_hash = "register data tidak valid";
        this.form.username = "register data tidak valid";
        this.form.nama_lengkap = "register data tidak valid";
        this.form.uuid = "register data tidak valid";
      }
    },
  },
  computed: {
    ...mapGetters(["listUsers", "currentUser"]),
    token_harian() {
      let md5now = CryptoService.md5(
        JSON.stringify({
          appid: "gq90w3845hfg",
          username: this.token.username,
          date: this.token.tanggal_token,
        }),
      );
      return md5now;
    },
    token_register() {
      let encrypted = CryptoService.encrypt(
        JSON.stringify({
          username: this.form.username,
          nama_lengkap: this.form.nama_lengkap,
          password: this.form.password_hash,
          level_akses: this.form.level_akses,
          fitur: this.form.fitur,
          uuid: this.form.uuid,
          tanggal: Date.now(),
          entropy: "a",
        }),
      );
      return encrypted;
    },
    token_berjangka() {
      if (this.token.tanggal_expired_token == null) {
        return "";
      }
      let payload = JSON.stringify({
        username: this.token.username,
        uuid: this.token.uuid,
        expired: new Date(this.token.tanggal_expired_token).getTime(),
      });
      let encrypted = CryptoService.encrypt(payload);
      if (this.token.username != "") this.$store.dispatch("updateTokenUser", { username: this.token.username, last_exp: this.token.tanggal_expired_token, last_token: encrypted });
      return "*Login Token " + this.token.username + " (Exp: " + this.dateMoment(this.token.tanggal_expired_token).format("DD/MM/YYYY HH:mm:ss") + ")* \n\n" + encrypted;
    },
  },
  components: {},
  mounted() {
    //admin only
    if (!this.currentUser.level_akses == "1") {
      console.log(this.currentUser);
      this.$router.push({ name: "restricted" });
    }
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "User" }, { title: "User Manager" }]);
  },
  methods: {
    modalToken(username) {
      let user = this.listUsers.find(item => item.username == username);
      this.token.tanggal_expired_token = user.last_exp;
      this.token.username = username;
      this.token.uuid = user.uuid;
      this.$refs["modal-token"].show();
    },
    formModal(username) {
      if (username == null) {
        this.form = {
          edit: false,
          register_data: "",
          password_hash: "",
          username: "",
          nama_lengkap: "",
          uuid: "",
          level_akses: 0,
          fitur: [],
        };
      } else {
        let edititem = this.listUsers.find(item => item.username == username);
        this.input_register_data = edititem.register_data;
        this.form = {
          edit: edititem.username,
          register_data: edititem.register_data,
          password_hash: edititem.password_hash,
          username: edititem.username,
          nama_lengkap: edititem.nama_lengkap,
          uuid: edititem.uuid,
          level_akses: edititem.level_akses,
          fitur: edititem.fitur,
        };
      }
      this.$refs["modal-add-user"].show();
    },
    simpanUser() {
      if (this.form.username == "" || this.form.nama_lengkap == "") {
        Swal.fire({
          title: "Salah!",
          text: "Data tidak lengkap",
          icon: "error",
          heightAuto: false,
        });
        return false;
      }
      this.$store.dispatch("saveUser", this.form).then(() => {
        this.$refs["modal-add-user"].hide();
        Swal.fire({
          title: "Berhasil!",
          text: "User berhasil disimpan",
          icon: "success",
          heightAuto: false,
        });
      });
    },
    hapusUser(username) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data " + username + " akan dihapus permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteUser", username).then(() => {
            Swal.fire({
              title: "Berhasil!",
              text: "User berhasil dihapus",
              icon: "success",
              heightAuto: false,
            });
          });
        }
      });
    },
    copyTokenHarian() {
      this.$refs["input_token_harian"].focus();
      this.$refs["input_token_harian"].select();
      try {
        document.execCommand("copy");
        this.$bvToast.toast("Token " + this.$refs["input_token_harian"].value + " berhasil di copy", {
          title: `Copy Token`,
          variant: "success",
          solid: true,
        });
      } catch (err) {
        console.log("Oops, unable to copy");
      }
    },
    copyTokenRegister() {
      this.$refs["input_token_register"].focus();
      this.$refs["input_token_register"].select();
      try {
        document.execCommand("copy");
        this.$bvToast.toast("Token register berhasil di copy", {
          title: `Copy Token`,
          variant: "success",
          solid: true,
        });
      } catch (err) {
        console.log("Oops, unable to copy");
      }
    },
    copyTokenBerjangka() {
      this.timeTest();
      this.$refs["input_token_berjangka"].focus();
      this.$refs["input_token_berjangka"].select();
      try {
        document.execCommand("copy");
        this.$bvToast.toast("Token berjangka berhasil di copy", {
          title: `Copy Token`,
          variant: "success",
          solid: true,
        });
      } catch (err) {
        console.log("Oops, unable to copy");
      }
    },
    timeTest() {
      let tzoffset = new Date().getTimezoneOffset() * 60 * 1000; //offset in milliseconds
      let a = this.token.tanggal_expired_token; //inputan (GMT+7)
      let b = new Date(a).getTime(); //inputan versi timestamp (GMT0)
      let c = new Date(b).toISOString(); //inputan versi iso (GMT0)
      let d = new Date(b - tzoffset).toISOString(); //inputan versi offset local (GMT+7)
      let e = Date.now(); //browser timestamp (GMT0)
      let f = new Date(e).toISOString(); //browser versi iso (GMT0)
      let g = new Date(e - tzoffset).toISOString(); //browser versi offset local (GMT+7)

      let h = new Date(a + ":59").getTime(); //inputan versi timestamp+59 (GMT0)
      let i = new Date(a + ":59").toISOString(); //inputan versi iso+59 (GMT0)
      console.log(a);
      console.log(b);
      console.log(c);
      console.log(d);
      console.log(e);
      console.log(f);
      console.log(g);
      console.log(h);
      console.log(i);
    },
    dateMoment(date) {
      return moment(date);
    },
  },
};
</script>
